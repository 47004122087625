import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import router from '../../../router'
import store from '../../../store'

export default class ReplicationStatus{
  constructor() {
    this.update = {
      TH: '',
      CAD: ''
    }
  }
  getStatus(aws,apiKey) {
    axios.get(`${aws}getLatestUpload`, apiKey)
      .then(res => {
        this.update.CAD = moment(res.data[0].created_date).utc().locale('ja').format('LL');
      }).catch(err=>{
        if(err.response.status == 403 || err.response.status == 401){
          Swal.fire({
            icon: "info",
            title: `Token Expired System will forced logout`,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              store.commit("STORE_USERINFO", null);
              store.commit("STORE_SALESOFFICE", null)
              store.commit("STORE_CHART_URL", null);
              router.push("/login");
            }
          });
          }
      });

    axios.get(`${aws}getLatestUploadPlanet`, apiKey)
      .then(res => {
        this.update.TH = moment(res.data[0].SentProcessRequestDate).utc().locale('ja').format('LL');
      }).catch(err=>{
        if(err.response.status == 403 || err.response.status == 401){
          Swal.fire({
            icon: "info",
            title: `Token Expired System will forced logout`,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              store.commit("STORE_USERINFO", null);
              store.commit("STORE_SALESOFFICE", null)
              store.commit("STORE_CHART_URL", null);
              router.push("/login");
            }
          });
        }
      });
  }
}
