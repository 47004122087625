<template>
<div>
    <v-card width="470" style="position: absolute; right: 0; top: 1rem;" outlined>
        <v-toolbar  flat height="48"  style="background-color:#CFD8DC">
            <v-divider horizontal />
            <h4 class="ml-2 mr-2">更新日</h4>
            <v-icon small>mdi-file-clock-outline</v-icon>
            <v-divider horizontal />
        </v-toolbar>
        <v-card flat class="pa-2" style="overflow-y:auto;" >
            <v-row no-gutters >
                <v-col>
                <v-card class="ml2 mr-2 mb-2 pa-1" color="#E3F2FD" >
                    <v-card-text class=" text--primary">
                        <div class="d-flex">
                        <img src="../assets/Logo/new.gif" height="55px">
                        <h3 class="ml-1 mt-4" style="color: blue;">{{`${replicationStatus.update.CAD}時点のCADリクエストデータ` }}</h3>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card class="ml2 mr-2 mb-2 pa-1" color="#E3F2FD" >
                    <v-card-text class=" text--primary">
                    <div class="d-flex">
                        <img src="../assets/Logo/new.gif" height="55px">
                        <h3 class="ml-1 mt-4" style="color: blue;">{{`${replicationStatus.update.TH}時点での追加変更データ` }}</h3>
                    </div>
                    </v-card-text>
                </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-card>
    <v-card  width="470" style="position: absolute; right: 0; top: 19rem; overflow-y:auto;" outlined>
        <v-toolbar  flat height="48"  style="background-color:#CFD8DC">
            <v-divider horizontal />
            <h4 class="ml-2 mr-2">システムアップデート履歴</h4>
            <v-icon small>mdi-file-clock-outline</v-icon>
            <v-divider horizontal />
        </v-toolbar>
        <v-card flat :height="height" class="pa-2" style="overflow-y:auto;" >
            <v-row v-for="(item,i) in versionLogs.detailsOfUpdate " :key="i" no-gutters >
                <v-col>
                <v-divider horizontal />
                <v-card class="ml2 mr-2 mb-2 pa-1" color="#E3F2FD" >
                    <v-card-text class=" text--primary">
                    <div class="d-flex">
                        <v-chip outlined color="primary" label small>
                        <h4  >{{`Version : ${item.Version} ` }}</h4>
                        </v-chip>
                        <h4 class="ml-1 mr-2" style="color: blue;">{{` - ${item.DateRequested}` }}</h4>
                        <v-chip v-if="isWeekRange(item.DateRequested)" outlined label small color="#389DCA" class="shineEffect">New</v-chip>
                    </div>
                    <v-divider horizontal class="mt-2"  />
                        <h4 v-text="item.TitleJap?item.TitleJap:item.Title" style="font-weight: bold; font-style: italic;"></h4>
                        <span style="font-size: 11.5px;" v-html="item.ContentofRequestJap?item.ContentofRequestJap: item.ContentofRequest"></span>
                    </v-card-text>
                </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-card>
</div>
</template>


<script>
import VersionLogs from '../assets/class/Updates/VersionLogs.js'
import ReplicationStatus from '../assets/class/Updates/ReplicationStatus.js';
  export default {
    data() {
      return {
        versionLogs: new VersionLogs(),
        replicationStatus: new ReplicationStatus(),
        height: window.innerHeight - 420,
      }
    },
    methods: {
      async loadVersion() {
        this.apiKey.headers.Authorization = this.userInfo.Token
        await this.versionLogs.getLogs(this.aws,this.apiKey,this.deployment)
        await this.replicationStatus.getStatus(this.aws,this.apiKey)
        window.addEventListener('resize', () =>
        {
          this.height = window.innerHeight - 420
        })
      },
      isWeekRange(date) {
        if (!date) {
          return false;
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const oneWeekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        oneWeekAgo.setHours(0, 0, 0, 0);

        const dateFormat = new Date(date)
        dateFormat.setHours(0, 0, 0, 0);

        return dateFormat >= oneWeekAgo;
      },
    },
    mounted () {
      this.loadVersion()
    },
    computed: {
        userInfo() {
            return this.$root.decryptedUser;
        }
    }
  }
</script>

<style lang="scss" scoped>
  .center {
  position: absolute;
  top: 45%;
  left: 40%;
  transform: translate(-50%, -50%);
}
.shineEffect {
  position: relative;
  overflow: hidden;
}

.shineEffect::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, #389DCA, #ffffff, #389DCA); /* Adjust colors as needed */
  opacity: 0;
  transform: rotate(45deg) translateX(-100%);
  animation: shine 1.5s infinite;
}

@keyframes shine {
  0% {
    opacity: 0;
    transform: rotate(45deg) translateX(-100%);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translateX(100%);
  }
}

</style>