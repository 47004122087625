<template>
    <div>
        <div class="logo">
            <Logo/>
        </div>
        <div class="card">
            <SystemUpdates />
        </div>
    </div>
</template>

<script>
import Logo from '../components/Logo.vue';
import SystemUpdates from '../components/SystemUpdates.vue'
export default {
    components:{ Logo, SystemUpdates },
    data() {
        return {

        }
    },
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.logo{
    position: absolute;
    top: 45%;
    left: 40%;
    transform: translate(-50%, -50%);
}
.card{
    //input style
}
</style>
